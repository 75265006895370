import React, { useRef, useEffect, useState } from 'react';
import { default as paper, Raster, Point, Tool, Path, Layer } from 'paper';
import { Prompt, useParams, useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { toPairs } from 'lodash';

import './Canvas.scss';

import ToolbarItem from './components/ToolbarItem';
import * as Icons from './icons';
import Label from './components/Label';

// Used palette https://colorhunt.co/palette/159679
const PRIMARY_COLOR = 'rgb(240, 19, 22)';
const PRIMARY_COLOR_ALPHA = 'rgba(240, 19, 22, 0.5)';

const SECONDARY_COLOR = 'hsl(124, 64%, 66%)';
const SECONDARY_COLOR_ALPHA = 'hsl(124, 64%, 66%, 0.5)';

const TRINARY_COLOR = 'rgb(64, 191, 193)'
const TRINARY_COLOR_ALPHA = 'rgb(64, 191, 193, 0.5)'

const colors = {
  1: PRIMARY_COLOR,
  2: SECONDARY_COLOR,
  3: TRINARY_COLOR
}

const colors_alpha = {
  1: PRIMARY_COLOR_ALPHA,
  2: SECONDARY_COLOR_ALPHA,
  3: TRINARY_COLOR_ALPHA
}

const HIT_OPTIONS = {
  segments: true,
  stroke: true,
  fill: true,
  tolerance: 5,
};

const ZOOM_STEPS = 0.2;
const POINT_LABEL_RADIUS = 6;

const Canvas = () => {
  let { experimentId, id } = useParams();
  let history = useHistory();
  const imgUrl = `/api/image/${id}/file`;

  const canvasDomNode = useRef();

  const zoomAndPanTool = useRef();
  const addPointLabelTool = useRef();
  const classPointLabelsLayer = useRef();

  const zoomInTool = useRef();
  const zoomOutTool = useRef();

  const [currentTool, setCurrentTool] = useState('zoomAndPanTool');
  const currentClass = useRef();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [selected, setSelected] = useState(false);

  const [image, setImage] = useState();

  const removeLabel = () => {
    classPointLabelsLayer.current.children.forEach(item => {
      if (item.selected === true) {
        item.remove();
      }
    });

    setSelected(false);
  };

  useEffect(() => {
    paper.setup(canvasDomNode.current);

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Add background image
    const raster = new Raster({ source: imgUrl });
    raster.on('load', () => {
      // For some "reason" the origin of the image is in the center. Bad api design :-(
      raster.position = new Point(
        raster.bounds.width / 2,
        raster.bounds.height / 2
      );
    });

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Pan and zoom tool

    zoomAndPanTool.current = new Tool();

    // https://github.com/paperjs/paper.js/issues/525#issuecomment-289361893
    let lastPoint;

    zoomAndPanTool.current.onMouseDown = event => {
      lastPoint = paper.view.projectToView(event.point);
    };

    zoomAndPanTool.current.onMouseDrag = event => {
      const point = paper.view.projectToView(event.point);
      const last = paper.view.viewToProject(lastPoint);
      paper.view.scrollBy(last.subtract(event.point));
      lastPoint = point;
    };

    global.view = paper.view;

    zoomInTool.current.onclick = event => {
      paper.view.zoom += ZOOM_STEPS;
    };

    zoomOutTool.current.onclick = event => {
      if (paper.view.zoom > ZOOM_STEPS) {
        paper.view.zoom -= ZOOM_STEPS;
      }
    };

    ///////////////////////////////////////////////////////////////////////////////////////////////
    // Add point labeling tools

    addPointLabelTool.current = new Tool();
    classPointLabelsLayer.current = new Layer();
    paper.project.addLayer(classPointLabelsLayer.current);

    let hitPath = null;

    addPointLabelTool.current.onMouseDown = event => {
      setUnsavedChanges(true);
      classPointLabelsLayer.current.selected = false;

      const hitResult = classPointLabelsLayer.current.hitTest(
        event.point,
        HIT_OPTIONS
      );
      if (hitResult) {
        setSelected(true);

        hitPath = hitResult.item;
        hitPath.selected = true;
      } else {
        setSelected(false);
        // Add new label
        hitPath = null;

        const labelClass = currentClass.current;
        const circlePath = new Path.Circle({
          center: event.point,
          radius: POINT_LABEL_RADIUS,
          strokeColor: colors[labelClass],
          strokeWidth: 2,
          fillColor: colors_alpha[labelClass],
          data: {
            id: uuid(),
            labelClass,
          },
        });

        classPointLabelsLayer.current.addChild(circlePath);
      }
    };

    addPointLabelTool.current.onMouseDrag = event => {
      setUnsavedChanges(true);
      if (hitPath) {
        hitPath.position = hitPath.position.add(event.delta);
      }
    };

    addPointLabelTool.current.onKeyDown = event => {
      if (event.key === 'backspace' || event.key === 'delete') {
        removeLabel();
      }
    };

    zoomAndPanTool.current.activate(); // Initial default tool

    ////////////////////////
    // load existing labels
    const fetchLabels = async () => {
      const imageResponse = await fetch(`/api/image/${id}`);
      const image = await imageResponse.json();
      setImage(image);

      if (image.state !== 'UNLABELED') {
        image.labels.forEach(label => {
          classPointLabelsLayer.current.addChild(
            new Path.Circle({
              center: new Point(label.pos),
              radius: POINT_LABEL_RADIUS,
              strokeColor:
                colors[label.labelClass],
              strokeWidth: 2,
              fillColor:
                colors_alpha[label.labelClass],
              data: {
                id: label.id,
                labelClass: label.labelClass,
              },
            })
          );
        });
      }
    };

    fetchLabels();
  }, [id, imgUrl]);

  const saveLabels = async () => {
    try {
      const labels =
        classPointLabelsLayer.current &&
        classPointLabelsLayer.current.children &&
        classPointLabelsLayer.current.children.map(item => ({
          id: item.data.id,
          labelClass: item.data.labelClass,
          pos: [item.position.x, item.position.y],
        }));

      await fetch(`/api/label/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id,
          labels,
        }),
      });

      setUnsavedChanges(false);

      history.push(`/experiment/${experimentId}`);
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="Canvas">
      <Prompt
        when={unsavedChanges}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <div className="Canvas__toolbar">
        <div>
          <ToolbarItem onClick={saveLabels}>
            <Icons.Check />
          </ToolbarItem>
        </div>
        <div>
          <ToolbarItem ref={zoomInTool}>
            <Icons.ZoomIn />
          </ToolbarItem>
          <ToolbarItem ref={zoomOutTool}>
            <Icons.ZoomOut />
          </ToolbarItem>
        </div>
        <div>
          <ToolbarItem
            selected={currentTool === 'zoomAndPanTool'}
            onClick={() => {
              zoomAndPanTool.current.activate();
              setCurrentTool('zoomAndPanTool');
            }}
          >
            <Icons.PanTool />
          </ToolbarItem>
          {image &&
            toPairs(image.labelMap).map(([label, name], index) => (
              <ToolbarItem
                key={index}
                selected={currentTool === `labelTool-${label}`}
                color={colors[index+1]}
                onClick={() => {
                  addPointLabelTool.current.activate();
                  currentClass.current = parseInt(label, 10);
                  setCurrentTool(`labelTool-${label}`);
                }}
              >
                <Label name={name} />
              </ToolbarItem>
            ))}
        </div>
        {selected && (
          <div>
            <ToolbarItem onClick={removeLabel}>
              <Icons.Delete />
            </ToolbarItem>
          </div>
        )}
      </div>
      <canvas className="Canvas__canvas" ref={canvasDomNode} />
    </div>
  );
};

export default Canvas;
