import React from 'react';

const Check = props => (
  <svg width={24} height={24} {...props}>
    <path d="M0 0h24v24H0z" fill="none" />
    <path fill="currentColor" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
  </svg>
);

export default Check;
