import React, { forwardRef } from 'react';
import cn from 'classnames';

import './ToolbarItem.scss';

const ToolbarItem = forwardRef(({ children, selected, color, ...rest }, ref) => (
  <button
    className={cn('ToolbarItem', { selected })}
    style={{ color }}
    type="button"
    style={{ color }}
    {...rest}
    ref={ref}
  >
    {children}
  </button>
));

export default ToolbarItem;
