import React, { forwardRef } from 'react';
import cn from 'classnames';
import './SelectField.scss';

const SelectField = forwardRef(({ label, error, placeholder, children, value, ...rest }, ref) => (
  <label className={cn('SelectField', { error, placeholder: !value })}>
    <div className="row">
      <div className="label">{label}</div>
      <div className="error">{error}</div>
    </div>
    <select className="select" value={value ? value : ''} ref={ref} {...rest}>
      <option value="" disabled hidden>
        {placeholder}
      </option>
      {children}
    </select>
  </label>
));

export default SelectField;
