import React from 'react';
import cn from 'classnames';

import './IconButton.scss';

const IconButton = ({ children, small, minor, ...rest }) => (
  <button type="button" className={cn('IconButton', { small, minor })} {...rest}>
    {children}
  </button>
);

export default IconButton;
