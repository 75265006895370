import React from 'react';
import cn from 'classnames';
import './Field.scss';

const Field = ({ label, error, children }) => (
  <label className={cn('Field', { error })}>
    <div className="row">
      <div className="label">{label}</div>
      <div className="error">{error}</div>
    </div>
    <div>{children}</div>
  </label>
);

export default Field;
