export const upload = ({
  url,
  body,
  headers = { Accept: 'application/json', ContentType: 'multipart/form-data' },
  method = 'POST',
  onProgress = null,
  onUploadComplete = () => {}
}) =>
  new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    const form = new FormData();

    const onLoad = event => {
      try {
        resolve(JSON.parse(event.target.response));
      } catch (e) {
        reject(new Error('Invalid JSON response'));
      }
    };

    if (onProgress) {
      xhr.upload.addEventListener('progress', event => {
        if (event.lengthComputable && onProgress) {
          onProgress(event.loaded / event.total);
        }
      });
    }

    xhr.upload.addEventListener('error', () => reject(new Error('Upload Error')));
    xhr.upload.addEventListener('abort', () => reject(new Error('Upload Abort')));
    xhr.upload.addEventListener('load', onUploadComplete); // Called when the upload is completed
    xhr.addEventListener('error', () => reject(new Error('Download Error')));
    xhr.addEventListener('load', onLoad);

    xhr.open(method.toUpperCase(), url);

    if (headers) {
      Object.entries(headers).forEach(([header, value]) => {
        xhr.setRequestHeader(header, value);
      });
    }

    Object.entries(body).forEach(([key, value]) => {
      if (value instanceof FileList) {
        for (var file in value) {
          form.append(key, value[file]);
        }
      } else {
        form.append(key, value);
      }
    });

    xhr.send(form);
  });

export default upload;
