import React, { useState } from 'react';
import cn from 'classnames';

import './UploadArea.scss';

import * as Icons from '../icons';

const UploadArea = ({ uploadInProgress, uploadProgress, onInputChange = () => {} }) => {
  const [dragOver, setDragOver] = useState(0);

  return (
    <label
      className={cn('UploadArea', { uploadInProgress, uploadProgress, dragOver })}
      onDragEnter={() => setDragOver(dragOver + 1)}
      onDragLeave={() => setDragOver(dragOver - 1)}
      onDragOver={event => {
        if (uploadInProgress) return;

        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
      }}
      onDrop={event => {
        if (uploadInProgress) return;

        event.preventDefault();
        setDragOver(0);

        if (event.dataTransfer.files.length > 0) {
          onInputChange(event.dataTransfer.files);
        }

        // Clear dataTransfer input
        event.dataTransfer.value = null;
      }}
    >
      {uploadInProgress ? (
        <span className="content">
          <span className="label">Uploading {Math.round(uploadProgress * 100)}% ...</span>
          <div className="bar">
            <div className="inner" style={{ width: `${uploadProgress * 100}%` }} />
          </div>
        </span>
      ) : (
        <span className="content">
          <Icons.Upload className="icon" />
          <span className="label">
            <span className="color1">Select files</span> or drag and drop any images of this
            experiment here.
          </span>
          <input
            type="file"
            className="input"
            multiple
            accept="image/jpeg,image/png,.tif,.tiff"
            onChange={event => {
              if (event.target.files.length > 0) {
                onInputChange(event.target.files);
              }
              // Clear file input
              event.target.value = null;
            }}
          />
        </span>
      )}
    </label>
  );
};

export default UploadArea;
