import React from 'react';

import './Form.scss';

const Form = ({ children, header, footer, ...rest }) => (
  <form className="Form" {...rest}>
    <div className="header">{header}</div>
    <div className="body">{children}</div>
    <div className="footer">{footer}</div>
  </form>
);

export default Form;