import React, { useState } from 'react';

import UploadArea from './components/UploadArea';
import upload from './service/upload';

import './Upload.scss';

const Upload = ({ onFileAdded = () => {}, experimentId }) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const uploadFiles = async files => {
    setUploadInProgress(true);
    const response = await upload({
      url: `/api/experiment/${experimentId}/upload`,
      body: { images: files },
      onProgress: setUploadProgress
    });
    onFileAdded(response);
    setUploadInProgress(false);
  };

  return (
    <UploadArea
      uploadInProgress={uploadInProgress}
      uploadProgress={uploadProgress}
      onInputChange={uploadFiles}
    />
  );
};

export default Upload;
