import React from 'react';

import './MainLayout.scss';

const MainLayout = ({ children, aside, asideFooter, asideHeader }) => (
  <div className="MainLayout">
    <div className="asideHeader">{asideHeader}</div>
    <div className="aside">{aside}</div>
    <div className="asideFooter">{asideFooter}</div>
    <div className="main">
      <div className="content">{children}</div>
    </div>
  </div>
);

export default MainLayout;
