import React, { useState } from 'react';

const SubContent = ({ children, content }) => {
  const [showSubContent, setShowSubContent] = useState(false);

  return (
    <div>
      <div
        style={{
          display: showSubContent ? 'none' : 'initial'
        }}
      >
        {children(() => setShowSubContent(true))}
      </div>
      {showSubContent && content(() => setShowSubContent(false))}
    </div>
  );
};

export default SubContent;
