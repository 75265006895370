import React from 'react';
import cn from 'classnames';

import './OptionButton.scss';

const OptionButton = ({ children, extra, selected, block, ...rest }) => (
  <button className={cn('OptionButton', { selected, block })} type="button" {...rest}>
    <div className="children">{children}</div>
    <div className="extra">{extra}</div>
  </button>
);

export default OptionButton;
