import React from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import FullScreenLayout from './components/FullScreenLayout';
import Form from './components/Form';
import Headline from './components/Headline';
import Button from './components/Button';
import TextField from './components/TextField';

const LoginPage = ({ onLogin }) => {
  const history = useHistory();
  const { register, handleSubmit, errors, formState, setError } = useForm();

  const onSubmit = async data => {
    const response = await fetch(`/api/user/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    const responseObject = await response.json();

    if (!responseObject.ok) {
      setError(responseObject.field, 'server', responseObject.error);
      return;
    }

    onLogin(responseObject.user);
    history.push('/experiment');
  };

  return (
    <FullScreenLayout narrow>
      <Form
        header={
          <>
            <Headline>Login</Headline>
            <div className="minor">
              Welcome to neurlalab! Enter your username and password to login or click on signup to
              create a new account.
            </div>
          </>
        }
        footer={
          <div className="columns">
            <Button type="submit" disabled={formState.isSubmitting}>
              Login
            </Button>
            <Button
              minor
              disabled={formState.isSubmitting}
              onClick={() => {
                history.push('/signup');
              }}
            >
              Signup
            </Button>
          </div>
        }
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          placeholder="Enter your e-mail"
          label="E-Mail"
          name="email"
          ref={register({
            required: 'e-mail is required!',
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
              message: 'Invalid address!'
            }
          })}
          error={errors.email && errors.email.message}
        />
        <TextField
          placeholder="Enter your password"
          label="Password"
          type="password"
          name="password"
          ref={register({
            required: 'is required!',
            minLength: { value: 6, message: 'is too small!' }
          })}
          error={errors.password && errors.password.message}
        />
      </Form>
    </FullScreenLayout>
  );
};

export default LoginPage;
