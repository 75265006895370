import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import './App.scss';

import Canvas from './Canvas';
import ExperimentPage from './ExperimentPage';
import LoginPage from './LoginPage';
import SignupPage from './SignupPage';
import FullScreenLayout from './components/FullScreenLayout';

const Logout = ({ onLogout }) => {
  useEffect(() => {
    const logout = async () => {
      await fetch('/api/user', { method: 'DELETE' });
      onLogout();
    };

    logout();
  }, [onLogout]);

  return <Redirect to="/" />;
};

const App = () => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const getUser = async () => {
      const userResponse = await fetch(`/api/user`);
      const userResult = await userResponse.json();
      if (userResult.ok) {
        setUser(userResult.user);
      } else {
        setUser(null);
      }
    };

    getUser();
  }, []);

  // Loading
  if (user === undefined) {
    return (
      <div className="App">
        <FullScreenLayout narrows>
          <span className="minor">Loading...</span>
        </FullScreenLayout>
      </div>
    );
  }

  // Not authenticated
  if (user === null) {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage onLogin={setUser} />
            </Route>
            <Route path="/signup">
              <SignupPage />
            </Route>
            <Route>
              <Redirect to="/login" />
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact>
            <Redirect to="/experiment" />
          </Route>
          <Route
            path="/experiment/:experimentId/edit/:id"
            children={<Canvas />}
          />
          <Route path="/experiment/:id?">
            <ExperimentPage user={user} />
          </Route>
          <Route path="/logout" exact>
            <Logout onLogout={() => setUser(null)} />
          </Route>
          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
