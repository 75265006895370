import React from 'react';

import './ContentLayout.scss';

const ContentLayout = ({ children, header, action, footer }) => (
  <div className="ContentLayout">
    <div className="header">
      <div className="left">{header}</div>
      <div className="right">{action}</div>
    </div>
    <div className="content">{children}</div>
    <div className="footer">{footer}</div>
  </div>
);

export default ContentLayout;
