import React, { useState, useEffect, useCallback } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Moment from "react-moment";
import { lowerCase, groupBy } from "lodash";

import AsyncButton from "./components/AsyncButton";
import Table from "./components/Table";
import Label from "./components/Label";
import Headline from "./components/Headline";
import ContentLayout from "./components/ContentLayout";
import Button from "./components/Button";

import Upload from "./Upload";
import IconButton from "./components/IconButton";
import * as Icons from "./icons";

const ImagesPartial = ({
  experiment,
  onRemoveExperiment,
  predictionModel,
  onRetrainPredictionModel,
}) => {
  const history = useHistory();
  let { id } = useParams();
  const [images, setImages] = useState([]);

  const load = useCallback(async () => {
    const response = await fetch(`/api/experiment/${id}/image`);
    const result = await response.json();
    setImages(result.files);
  }, [setImages, id]);

  const predictLabels = async (experiment) => {
    await fetch(`/api/label/${experiment._id}`);
  };

  const removeFile = useCallback(
    async (file) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        confirm(
          `You are about to remove the image "${file.fileName}"\nAre you Sure?`
        )
      ) {
        await fetch(`/api/image/${file._id}`, { method: "DELETE" });
        load();
      }
    },
    [load]
  );

  useEffect(() => {
    load();
  }, [id]);

  const hasImages = images && images.length > 0;
  const areSomeImagesVerified = images.some(
    (image) => image.state === "VERIFIED"
  );

  const renderLabels = (file) => {
    const labels = groupBy(file.labels, "labelClass");
    return Object.keys(labels).map((labelClass) => (
      <Label
        key={labelClass}
        name={lowerCase(file.labelMap[labelClass])}
        minor={file.state !== "VERIFIED"}
      >
        {labels[labelClass].length}
      </Label>
    ));
  };

  return (
    <ContentLayout
      header={
        <>
          <Headline
            large
            extra={experiment.exposureTime}
            sub={
              experiment.date && (
                <Moment format="dddd, Do MMMM YYYY">{experiment.date}</Moment>
              )
            }
          >
            {experiment.name}
          </Headline>
          <Label name="Prediction Model">{predictionModel.name}</Label>
        </>
      }
      action={
        <>
          <Button minor small href={`/api/experiment/export_model/${id}`}>
            Export AI Model
          </Button>
          <Button minor small href={`/api/experiment/export/${id}`}>
            Export CSV
          </Button>
          <Button
            minor
            small
            onClick={() => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm(
                  `You are about to remove the experiment "${experiment.name}"\nAre you Sure?`
                )
              ) {
                onRemoveExperiment(id);
              }
            }}
          >
            Delete
          </Button>
          <Button
            minor
            small
            onClick={() => history.push(`/experiment/edit/${id}`)}
          >
            Edit
          </Button>
        </>
      }
    >
      {experiment.description && <p>{experiment.description}</p>}
      <Upload onFileAdded={load} experimentId={id} />

      {hasImages ? (
        <>
          {!predictionModel.isReady ? (
            <p>
              The next step is to label some of your cell images to complete the
              setup of the "{predictionModel.name}" prediction model.
              <br />
              Please tell us what you are looking for in your image. Click at
              least on one image and fully label it. Please choose images which
              fully represent the features you are looking for. After you
              completed that click on "Train prediction model" to finish the
              setup process.
            </p>
          ) : (
            <p>
              Click on “Predict“ and our algorithm will automatically label the
              cells for you. However, you still need to check every single image
              and add missing labels and correct mislabeled cells to verify the
              result.
              <br />
              It's very important that you label the cells very carefully. The
              algorithm is only as good as your labeling.
              <br />
              After you have verified a few images, you can click on "Improve
              prediction model" to retrain the model with all verified images,
              in order to further improve the quality of the algorithm.
            </p>
          )}
          <div className="list">
            <AsyncButton
              minor={predictionModel.isReady}
              disabled={!areSomeImagesVerified}
              onClick={onRetrainPredictionModel}
              loading="Training in process..."
            >
              {predictionModel.isReady
                ? "Improve prediction model"
                : "Train prediction model"}
            </AsyncButton>

            <AsyncButton
              minor={!predictionModel.isReady}
              disabled={!predictionModel.isReady}
              onClick={async () => {
                await predictLabels(experiment);
                await load(); // reload file list
              }}
              loading="Predicting..."
            >
              Predict
            </AsyncButton>
          </div>
        </>
      ) : (
        <p>
          Drag and drop your cell images from your computer into the area above.
          <br />
          You can upload multiple image files at once. We prefer .png files but
          .tiff and .jpg work as well.
        </p>
      )}

      {hasImages && (
        <div>
          <Headline extra={`(${images.length})`}>
            Images of the experiment
          </Headline>

          <Table>
            <thead>
              <tr>
                <th>Filename</th>
                <th>Date</th>
                <th>Labels</th>
                <th>Verified</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {images.map((file) => (
                <tr key={file._id}>
                  <td>
                    <Link to={`/experiment/${id}/edit/${file._id}`}>
                      {file.fileName}
                    </Link>
                  </td>
                  <td>
                    <Moment fromNow>{file.date}</Moment>
                  </td>
                  <td className="list">
                    {file.state !== "UNLABELED" ? (
                      renderLabels(file)
                    ) : (
                      <span className="minor">not labeled yet</span>
                    )}
                  </td>
                  <td>
                    {predictionModel.isReady && file.state === "UNLABELED" ? (
                      <span>Ready to predict</span>
                    ) : file.state === "VERIFIED" ? (
                      <span>Verified</span>
                    ) : (
                      <span className="minor">not verified yet</span>
                    )}
                  </td>
                  <td className="action">
                    <IconButton small minor onClick={() => removeFile(file)}>
                      <Icons.Close />
                    </IconButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </ContentLayout>
  );
};

export default ImagesPartial;
