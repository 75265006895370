import React from 'react';
import cn from 'classnames';

import './Label.scss';

const Label = ({ name, children, primary, secondary, minor }) => (
  <div className={cn('Label', { primary, secondary, minor, hasChildren: !!children })}>
    <div className="name">{name}</div>
    {children && <div className="amount">{children}</div>}
  </div>
);

export default Label;
