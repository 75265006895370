import React from 'react';

import './FieldRow.scss';

const FieldRow = ({ desc, children }) => (
  <div className="FieldRow">
    <div>{children}</div>
    <div>{desc}</div>
  </div>
);

export default FieldRow;
