import React from 'react';
import cn from 'classnames';

import './FullScreenLayout.scss';

const FullScreenLayout = ({ children, narrow }) => (
  <div className={cn('FullScreenLayout', { narrow })}>
    <div className="content">{children}</div>
  </div>
);

export default FullScreenLayout;
