import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import cn from 'classnames';

import Form from './components/Form';
import Headline from './components/Headline';
import Button from './components/Button';
import FieldRow from './components/FieldRow';
import TextField from './components/TextField';
import Field from './components/Field';
import SelectField from './components/SelectField';
import Label from './components/Label';

import PredictionModelForm from './PredictionModelForm';
import SubContent from './SubContent';

const ExperimentForm = ({
  experiment = undefined,
  predictionModels,
  onCreate = () => {},
  onUpdate = () => {},
  onCreatePredictionModel = () => {},
}) => {
  const {
    register,
    handleSubmit,
    errors,
    formState,
    control,
    setValue,
    watch,
    triggerValidation,
  } = useForm({
    defaultValues: experiment,
    mode: 'onChange',
  });

  const predictionModelId = watch('predictionModelId');
  const predictionModel = predictionModels.find(
    model => model._id === predictionModelId
  );
  const isCreateMode = !experiment;
  const hasPredictionModels = predictionModels.length !== 0;

  const onSubmit = async data => {
    if (isCreateMode) {
      return await onCreate(data);
    } else {
      return await onUpdate(data);
    }
  };

  return (
    <SubContent
      content={closeSubContent => (
        <PredictionModelForm
          onCreate={async predictionModel => {
            const predictionModelId = await onCreatePredictionModel(
              predictionModel
            );
            setValue('predictionModelId', predictionModelId);
            triggerValidation();
            closeSubContent();
          }}
        />
      )}
    >
      {openSubContent => (
        <Form
          header={
            <Headline>
              {isCreateMode
                ? hasPredictionModels
                  ? 'Create a new experiment'
                  : 'Create your first experiment'
                : 'Edit the settings of the experiment'}
            </Headline>
          }
          footer={
            <div>
              <Button
                type="submit"
                disabled={formState.isSubmitting}
                minor={!formState.isValid}
              >
                {isCreateMode
                  ? formState.isSubmitting
                    ? 'Creating...'
                    : 'Create a new experiment'
                  : formState.isSubmitting
                  ? 'Updating...'
                  : 'Update experiment'}
              </Button>
            </div>
          }
          onSubmit={handleSubmit(onSubmit)}
        >
          <FieldRow
            desc={
              <p>
                Provide a name of the experiment. The name will be listed in the
                sidebar on the left to easily find the experiment later.
              </p>
            }
          >
            <TextField
              placeholder='e.g. "C2 Rad CsA fk VER VERO"'
              label="Name of the Experiment"
              name="name"
              ref={register({ required: true })}
              error={errors.name && 'Name is required!'}
            />
          </FieldRow>
          <FieldRow desc={<p>Optionally add the date of your experiment.</p>}>
            <TextField
              placeholder="e.g. Today"
              label="Date of the experiment"
              name="date"
              type="date"
              ref={register()}
              error={errors.date && 'Invalid date!'}
            />
          </FieldRow>

          <FieldRow desc={<p>Optionally add the exposure time.</p>}>
            <TextField
              placeholder='e.g. "3h"'
              label="Exposure time"
              name="exposureTime"
              ref={register()}
              error={errors.exposureTime && 'Invalid exposure time!'}
            />
          </FieldRow>

          <TextField
            label="Additional description"
            name="description"
            placeholder="Optionally provide add additional information about your experiment here."
            ref={register()}
          />

          <hr />
          {isCreateMode ? (
            <>
              <p>
                In order to automatically evaluate your experiment, please tell
                us more about your samples. Please define the features that you
                are interested in.
              </p>
              <FieldRow
                desc={
                  <Field label="Create a new prediction model for the experiment">
                    <Button
                      minor={hasPredictionModels}
                      onClick={openSubContent}
                    >
                      Create prediction model
                    </Button>
                  </Field>
                }
              >
                <div className={cn({ disabledArea: !hasPredictionModels })}>
                  <Controller
                    as={
                      <SelectField
                        placeholder="Select a prediction model"
                        label="Use an existing prediction model"
                      >
                        {predictionModels.map(({ _id, name }) => (
                          <option key={_id} value={_id}>
                            {name}
                          </option>
                        ))}
                      </SelectField>
                    }
                    control={control}
                    rules={{ required: true }}
                    onChange={([selected]) => ({
                      value: selected.target.value,
                    })}
                    name="predictionModelId"
                    error={errors.predictionModel && 'Choose one!'}
                  />
                </div>
              </FieldRow>
              <FieldRow
                desc={
                  <p>
                    You need to create a new prediction model if you are using a
                    different cell line or if you want to analyze a new feature
                    of cell morphology i.e. a visually different effect on
                    cells.
                  </p>
                }
              >
                <p className={cn({ disabledArea: !hasPredictionModels })}>
                  You can reuse the same prediction model if you are using the
                  same cell line and equipment. If you get poor results,
                  consider creating a new prediction model.
                </p>
              </FieldRow>
            </>
          ) : (
            <>
              <Label name="Prediction Model">{predictionModel.name}</Label>
              <p>
                You can't change the prediction model after you created your
                experiment at the moment. However, we will probably change that
                in the future.
              </p>
            </>
          )}
        </Form>
      )}
    </SubContent>
  );
};

export default ExperimentForm;
