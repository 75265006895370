import React from 'react';
import { useHistory } from 'react-router-dom';

import SignupForm from './SignupForm';
import FullScreenLayout from './components/FullScreenLayout';

const SignupPage = () => {
  const history = useHistory();

  const onSignup = async user => {
    const response = await fetch(`/api/user/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    });
    const { ok } = await response.json();
    if (!ok) throw new Error('User exists!');
    alert(
      'The signup process was successful!\nPlease use enter your credentials on the next page to login.'
    );
    history.push('/login');
  };

  return (
    <FullScreenLayout>
      <SignupForm onSignup={onSignup} />
    </FullScreenLayout>
  );
};

export default SignupPage;
