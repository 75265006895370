import React from 'react';
import cn from 'classnames';

import './Headline.scss';

const Headline = ({ children, extra, sub, large }) => (
  <div className={cn('Headline', { large })}>
    <div className="children">{children}</div>
    <span className="extra">{extra}</span>
    <div className="sub ">{sub}</div>
  </div>
);

export default Headline;
