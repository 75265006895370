import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Route, useParams, useHistory } from 'react-router-dom';
import { groupBy, toPairs } from 'lodash';

import Headline from './components/Headline';
import OptionButton from './components/OptionButton';
import Button from './components/Button';
import MainLayout from './components/MainLayout';
import IconButton from './components/IconButton';

import * as Icons from './icons';
import ImagesPartial from './ImagesPartial';
import ExperimentForm from './ExperimentForm';
import FullScreenLayout from './components/FullScreenLayout';

const ExperimentPage = ({ user }) => {
  let { id } = useParams();
  const history = useHistory();

  // TODO refactoring state management
  const [experiments, setExperiments] = useState(null);
  const [predictionModels, setPredictionModels] = useState(null);

  const loadExperiments = useCallback(async () => {
    const response = await fetch(`/api/experiment`);
    const result = await response.json();
    setExperiments(result.experiment);
  }, [setExperiments]);

  const loadPredictionModels = useCallback(async () => {
    const response = await fetch(`/api/prediction-model`);
    const result = await response.json();
    setPredictionModels(result.predictionModels);
  }, [setPredictionModels]);

  const create = useCallback(
    async experiment => {
      const response = await fetch(`/api/experiment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(experiment),
      });
      const result = await response.json();

      loadExperiments();
      history.push(`/experiment/${result.experiment._id}`);
    },
    [loadExperiments]
  );

  const createPredictionModel = useCallback(
    async predictionModel => {
      const response = await fetch(`/api/prediction-model`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(predictionModel),
      });
      const result = await response.json();
      // Update existing data store
      setPredictionModels([...predictionModels, result.predictionModel]);
      return result.predictionModel._id;
    },
    [predictionModels]
  );

  const retrainPredictionModel = useCallback(
    predictionModel => async () => {
      setTimeout(
        () =>
          alert(
            'Training of the prediction model has started...\nThis will usually take about 2-3 minutes.'
          ),
        1
      );
      await fetch(`/api/prediction-model/${predictionModel._id}`, {
        method: 'PUT',
      });
      loadPredictionModels();
    },
    [loadPredictionModels]
  );

  const update = useCallback(
    id => async experiment => {
      await fetch(`/api/experiment/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(experiment),
      });

      loadExperiments();
      history.push(`/experiment/${id}`);
    },
    [loadExperiments]
  );

  const remove = useCallback(async () => {
    await fetch(`/api/experiment/${id}`, { method: 'DELETE' });
    loadExperiments();
    history.push('/experiment/');
  }, [loadExperiments]);

  useEffect(() => {
    loadExperiments();
    loadPredictionModels();
  }, [loadExperiments, loadPredictionModels]);

  if (!experiments || !predictionModels) {
    return (
      <FullScreenLayout narrows>
        <span className="minor">Loading...</span>
      </FullScreenLayout>
    );
  }

  const renderExperiment = experiment => (
    <OptionButton
      key={experiment._id}
      block
      extra={experiment.date}
      selected={experiment._id === id}
      onClick={() => history.push(`/experiment/${experiment._id}`)}
    >
      {experiment.name}
    </OptionButton>
  );

  const experiment = experiments.find(experiment => experiment._id === id);
  const predictionModel = predictionModels.find(
    predictionModel =>
      experiment && experiment.predictionModelId === predictionModel._id
  );

  return (
    <MainLayout
      asideHeader={
        <>
          {user.isAdmin ? (
            <Headline>Admin</Headline>
          ) : (
            <Headline
              extra={`(${experiments.length || 'empty'})`}
            >{`${user.name}'s experiments`}</Headline>
          )}
          <IconButton
            small
            onClick={() => {
              if (
                // eslint-disable-next-line no-restricted-globals
                confirm(`Do you want to logout?"`)
              ) {
                history.push('/logout');
              }
            }}
          >
            <Icons.Power />
          </IconButton>
        </>
      }
      aside={
        <>
          {experiments.length > 0 ? (
            user.isAdmin ? (
              toPairs(groupBy(experiments, 'ownerName')).map(
                ([ownerName, e], idx) => [
                  <Headline key={idx}>{ownerName}</Headline>,
                  ...e.map(renderExperiment),
                ]
              )
            ) : (
              experiments.map(renderExperiment)
            )
          ) : (
            <>
              <p>
                Here we will show all of your experiments to provide an easy way
                for you to manage cell images.
              </p>
              <p>Click on the „New experiment“ button to start.</p>
              <p>
                When you have labeled your cells you can export the results
                using the „Excel export“ button.
              </p>
            </>
          )}
        </>
      }
      asideFooter={
        <div className="columns">
          <Button block onClick={() => history.push('/experiment/new')}>
            New experiment
          </Button>
          <Button minor block href="/api/experiment/export">
            Excel Export
          </Button>
        </div>
      }
    >
      <Switch>
        <Route path="/experiment" exact>
          <p>Please select an experiment on the left.</p>
        </Route>
        <Route path="/experiment/new">
          <ExperimentForm
            onCreate={create}
            predictionModels={predictionModels}
            onCreatePredictionModel={createPredictionModel}
          />
        </Route>
        <Route
          path="/experiment/edit/:id"
          render={({
            match: {
              params: { id },
            },
          }) => {
            const experiment = experiments.find(
              experiment => experiment._id === id
            );
            return (
              experiment && (
                <ExperimentForm
                  experiment={experiment}
                  onUpdate={update(id)}
                  predictionModels={predictionModels}
                  onCreatePredictionModel={createPredictionModel}
                />
              )
            );
          }}
        ></Route>
        <Route>
          {experiment && (
            <ImagesPartial
              experiment={experiment}
              predictionModel={predictionModel}
              onRemoveExperiment={remove}
              onRetrainPredictionModel={retrainPredictionModel(predictionModel)}
            />
          )}
        </Route>
      </Switch>
    </MainLayout>
  );
};

export default ExperimentPage;
