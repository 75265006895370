import React, { forwardRef } from 'react';
import cn from 'classnames';
import './TextField.scss';

const TextField = forwardRef(({ label, error, ...rest }, ref) => (
  <label className={cn('TextField', { error })}>
    <div className="row">
      <div className="label">{label}</div>
      <div className="error">{error}</div>
    </div>
    <input className="input" type="text" ref={ref} {...rest} />
  </label>
));

export default TextField;
