import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Form from './components/Form';
import Headline from './components/Headline';
import Button from './components/Button';
import FieldRow from './components/FieldRow';
import TextField from './components/TextField';

// TODO: update prediction model
const PredictionModelForm = ({ onCreate = () => {} }) => {
  const {
    register,
    handleSubmit,
    errors,
    watch,
    triggerValidation,
    formState,
  } = useForm();
  const watchNumberOfFeatures = watch('numFeatures');
  const numberOfFeatures = parseInt(watchNumberOfFeatures, 10) || 0;

  if (formState.touched.numFeatures) {
    triggerValidation('numFeatures');
  }

  const onSubmit = async data => {
    const name = `${data.cellType}: ${data.features.join(' / ')} (${
      data.microscope
    } ${data.zoom}x)`;
    return await onCreate({ name, ...data });
  };

  return (
    <Form
      header={
        <Headline>Setup prediction model for the new experiment</Headline>
      }
      footer={
        <div>
          <Button type="submit" disabled={formState.isSubmitting}>
            {formState.isSubmitting ? 'Creating...' : 'Create'}
          </Button>
        </div>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <p>
        In order to automatically evaluate your experiment, please tell us more
        about the samples. Please define the features you are interested in.
      </p>
      <FieldRow
        desc={<p>Please enter the name of the cell line or cell type.</p>}
      >
        <TextField
          placeholder='e.g. "HeLa"'
          label="Cell type"
          name="cellType"
          ref={register({ required: true })}
          error={errors.cellType && 'Cell type is required!'}
        />
      </FieldRow>
      <FieldRow
        desc={
          <p>
            You do not need to know the specific model of your microscope, but
            it is important to create a new prediction model for each of your
            microscopes.
          </p>
        }
      >
        <TextField
          placeholder='e.g. "Leica DMi1"'
          label="Microscope name"
          name="microscope"
          ref={register({ required: true })}
          error={errors.microscope && 'Microscope name is required!'}
        />
      </FieldRow>
      <FieldRow
        desc={
          <p>What is the zoom factor of your microscope for this experiment.</p>
        }
      >
        <TextField
          placeholder='e.g. "20"'
          label="Zoom factor"
          name="zoom"
          type="number"
          ref={register({ required: true })}
          error={errors.zoom && 'Zoom factor is required!'}
        />
      </FieldRow>

      <hr />

      <FieldRow
        desc={
          <p>
            For example, put 2 if you want to analyze flat or round (alive or
            dead) or put 1 if you are only interested in the total cell count.
            It’s possible to analyze more than 2 morphology features, e.g.
            intermediate additionally to flat and round cell morphology.
          </p>
        }
      >
        <TextField
          placeholder='e.g. "2"'
          label="Number of features"
          name="numFeatures"
          type="number"
          ref={register({
            required: {
              value: true,
              message: 'Number of features are required!',
            },
            min: { value: 1, message: 'Minimum is 1!' },
            max: { value: 3, message: 'Only 3 are supported!' },
          })}
          error={errors.numFeatures && errors.numFeatures.message}
        />
      </FieldRow>

      {numberOfFeatures > 0 && (
        <FieldRow
          desc={
            <p>
              {numberOfFeatures === 1
                ? 'What is the name of the feature? Use something like "cell" or "target".'
                : 'What is the name of the first feature? Use something like "normal", "flat" or "alive".'}
            </p>
          }
        >
          <TextField
            placeholder={
              numberOfFeatures === 1 ? 'e.g. "Cell"' : 'e.g. "Alive"'
            }
            label={
              numberOfFeatures === 1
                ? 'Name of the feature'
                : 'Name of 1st feature'
            }
            name="features[0]"
            ref={register({ required: true })}
            error={errors.features && errors.features[0] && 'Name is required!'}
          />
        </FieldRow>
      )}

      {numberOfFeatures > 1 && (
        <FieldRow
          desc={
            <p>
              What is the name of the second feature? Use something like
              "intoxicated"‚ "rounded" or "dead".
            </p>
          }
        >
          <TextField
            placeholder='e.g. "Dead"'
            label="Name of 2nd feature"
            name="features[1]"
            ref={register({ required: true })}
            error={errors.features && errors.features[1] && 'Name is required!'}
          />
        </FieldRow>
      )}

      {numberOfFeatures > 2 && (
        <FieldRow
          desc={
            <p>
              What is the name of the 3rd feature? Use something like
              "intermediate".
            </p>
          }
        >
          <TextField
            placeholder='e.g. "in-between"'
            label="Name of 3rd feature"
            name="features[2]"
            ref={register({ required: true })}
            error={errors.features && errors.features[2] && 'Name is required!'}
          />
        </FieldRow>
      )}

      {numberOfFeatures > 3 && (
        <FieldRow
          desc={
            <p>
              What is the name of the 4th feature? Use something like
              "contaminated".
            </p>
          }
        >
          <TextField
            placeholder='e.g. "contaminated"'
            label="Name of 4th feature"
            name="features[3]"
            ref={register({ required: true })}
            error={errors.features && errors.features[3] && 'Name is required!'}
          />
        </FieldRow>
      )}
    </Form>
  );
};

export default PredictionModelForm;
