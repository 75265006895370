import React, { createElement } from 'react';
import cn from 'classnames';

import './Button.scss';

const Button = ({ children, onClick, disabled, small, minor, block, href, ...rest }) =>
  createElement(
    href ? 'a' : 'button',
    {
      className: cn('Button', { small, minor, block }),
      type: 'button',
      role: 'button',
      disabled,
      onClick,
      href,
      ...rest
    },
    children
  );
export default Button;
