import React from 'react';
import { useForm } from 'react-hook-form';

import Form from './components/Form';
import Headline from './components/Headline';
import Button from './components/Button';
import TextField from './components/TextField';
import FieldRow from './components/FieldRow';

const SignupForm = ({ onSignup }) => {
  const {
    register,
    handleSubmit,
    errors,
    getValues,
    formState,
    setError,
  } = useForm();

  const onSubmit = async ({ email, institution, name, password }) => {
    const user = {
      email,
      institution,
      name,
      password,
    };

    try {
      await onSignup(user);
    } catch (error) {
      console.log('onSignup error', error);
      setError('email', 'server', 'Taken! Choose another address!');
    }
  };

  return (
    <Form
      header={<Headline>Signup</Headline>}
      footer={
        <>
          <div className="minor">
            Use those credentials at the login page. By clicking on signup you
            accept the{' '}
            <a
              href="https://neuralab.de/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms and conditions
            </a>
            .
          </div>
          <div>
            <Button type="submit" disabled={formState.isSubmitting}>
              {formState.isSubmitting ? 'Signup...' : 'Signup'}
            </Button>
          </div>
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <FieldRow
        desc={
          <p>
            You can ether enter your full name or provide just your first name
          </p>
        }
      >
        <TextField
          placeholder="Enter your name"
          label="Your name"
          name="name"
          ref={register({
            required: 'Name is required!',
            minLength: { value: 3, message: 'Is too short!' },
          })}
          error={errors.name && errors.name.message}
        />
      </FieldRow>
      <FieldRow desc={<p>We use this e-mail address to verify your account</p>}>
        <TextField
          placeholder="Enter your e-mail address"
          label="E-mail"
          name="email"
          ref={register({
            required: 'E-mail is required!',
            pattern: {
              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i,
              message: 'Invalid address!',
            },
          })}
          error={errors.email && errors.email.message}
        />
      </FieldRow>
      <FieldRow
        desc={<p>The name of your company, institution or department</p>}
      >
        <TextField
          placeholder="Enter the name of your institution"
          label="Institution and department"
          name="institution"
          ref={register({
            required: true,
          })}
          error={errors.name && 'Is required!'}
        />
      </FieldRow>
      <FieldRow
        desc={<p>This is the password which you need to login into neuralab</p>}
      >
        <TextField
          placeholder="Enter a new password"
          label="Password"
          name="password"
          type="password"
          ref={register({
            required: 'is required!',
            minLength: { value: 6, message: 'Is too small!' },
          })}
          error={errors.password && errors.password.message}
        />
      </FieldRow>
      <FieldRow desc={<p>Retype the same password again</p>}>
        <TextField
          placeholder="Repeat the same password"
          label="Retype password"
          name="password2"
          type="password"
          ref={register({
            required: true,
            validate: value => value === getValues().password,
          })}
          error={errors.password2 && "Passwords don't match!"}
        />
      </FieldRow>
    </Form>
  );
};

export default SignupForm;
