import React, { useState } from 'react';

import Button from './Button';

const AsyncButton = ({
  children,
  disabled,
  loading = 'Loading...',
  onClick = () => Promise.resolve(),
  ...rest
}) => {
  const [inProgress, setInProgress] = useState(false);

  const handleClick = async (...args) => {
    setInProgress(true);
    try {
      await onClick(...args);
    } catch (error) {
      setInProgress(false);
      throw error;
    }
    setInProgress(false);
  };

  return (
    <Button onClick={handleClick} {...rest} disabled={inProgress || disabled}>
      {inProgress ? loading : children}
    </Button>
  );
};

export default AsyncButton;
